import React from 'react';
import Layout from '../components/layout';

const IndexPage = () => (
  <Layout>
    <div className="has-text-centered">
      <h1 className="title">Marc Reisner</h1>
      <p className="subtitle">
        is a Server Systems engineer at Epic and a former stand-up comedian.
        <br />
        Follow him here:{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.twitter.com/fractal_boy"
        >
          @fractal_boy
        </a>
      </p>
    </div>
  </Layout>
);

export default IndexPage;
